<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/order_list' }">福利卡列表</el-breadcrumb-item>
					<el-breadcrumb-item>套餐详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				<template>
					<div class="order-status">
						<h4>
							状态：
							<span
								class="ns-text-color">{{taocanData.is_use==0?'未兑换':taocanData.is_use==1?'已兑换':taocanData.is_use==2?'已过期':''}}</span>
						</h4>
					</div>

					<div class="order-info">
						<h4>福利卡信息</h4>
						<div class="list">
							<div class="item">
								<div class="head" style="display: flex;align-items: center;">
									<span class="order-no" style="flex:1">发放日期：{{taocanData.fafang_date}}</span>
									<span class="order-no" style="flex:1">兑换有效期至：{{taocanData.youxiao_time}}</span>
									<span class="order-no" style="flex:1">兑换日期：{{taocanData.duihuan_time}}</span>

								</div>
								<ul v-for="(goodsItem, goodsIndex) in 1" :key="goodsIndex"
									style="width: 100%;margin-top: 10px;">
									<li>
										<div class="img-wrap">
											<img :src="'https://shuaichang.cgwzm.com/'+taocanData.taocan_pic" />
										</div>
										<div class="info-wrap">
											<h5>{{taocanData.ka_name}}</h5>
											<!-- <span>规格：规格值</span> -->
										</div>
									</li>
									<li>
										<span
											class="ns-text-color">{{taocanData.is_use==0?'未兑换':taocanData.is_use==1?'已兑换':taocanData.is_use==0?'已过期':''}}</span>
									</li>
									<!-- <li>
										<el-link :underline="false" @click="orderDetail(orderItem)">福利卡套餐详情</el-link>
									</li> -->
								</ul>
							</div>
						</div>
					</div>


					<!-- 	<nav>
						<li>套餐一</li>
						<li>单价</li>
						<li>数量</li>
						<li>小计</li>
						<li>操作</li>
					</nav> -->
					<!-- 组合套餐 -->
					<div style='color: #ed2920 !important;'>
						以下福利卡套餐只能选择其中一款
					</div>
					<div class="bundling-wrap" v-model="tabBundling">
						
						<div :label="item.bl_name" :name="'bundling_' + item.bl_id"
							style='border-bottom: 1px solid #eee;box-sizing: border-box;padding: 20px;'
							v-for="(item, index) in taocan" :key="index">
							<div style="text-align: center;margin-bottom: 20px;font-weight: 600;">
								{{item.bl_name}}
							</div>
							<div class="operation">
								<!-- <el-button type="primary" size="medium" @click="duihuan(item)">立即兑换</el-button> -->
							</div>
							<div class="suits" >
								<el-radio-group v-model="bl_id">
									<ul>
										<el-radio :label='item.bl_id' style="float: left;"><span></span> </el-radio>
										<li v-for="(goods, goods_index) in item.goodslist" :key="goods_index"
											@click="$router.pushToTab({ path: '/sku-' + goods.sku_id })">
											<div class="sku-img"><img :src="$img(goods.sku_image, { size: 'mid' })" />
											</div>
											<div class="sku-name">{{ goods.sku_name }}</div>
											<div class="sku-price ns-text-color">￥{{ goods.price }}</div>
										</li>
									</ul>
								</el-radio-group>
							</div>
						</div>
						<div style="text-align: center;margin-top: 20px;">
							<el-button type="primary" size="medium" :disabled='taocanData.is_use!=0' @click="duihuan()">立即兑换</el-button>
						</div>
					</div>

				</template>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		apiOrderDetail,
		getKalist
	} from '@/api/order/order';
	import orderMethod from '@/utils/orderMethod';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'order_detail',
		components: {},
		mixins: [orderMethod],
		data: () => {
			return {
				bl_id:'',
				taocanData: {},
				taocan: [],
				tabBundling: 'bundling_6',
				orderId: 0,
				orderDetail: null,
				loading: true,
				yes: true,
				bundling: [{
					"bl_id": 6,
					"bl_name": "套餐三",
					"site_id": 1,
					"site_name": "ndianv4",
					"bl_price": "0.00",
					"goods_money": "121.01",
					"shipping_fee_type": 1,
					"status": 1,
					"bundling_goods": [{
						"sku_id": 10,
						"sku_name": "厂家批发珍珠真不锈炒锅物理不粘锅炒菜锅搪瓷锅活动展会礼品锅 图色 平底32cm不带耳 平锅34cm",
						"price": "120.00",
						"sku_image": "upload/1/common/images/20230616/20230616090630168692079082854.jpg",
						"promotion_price": "120.00",
						"stock": 9,
						"unit": "",
						"goods_state": 1,
						"verify_state": 1
					}, {
						"sku_id": 1,
						"sku_name": "潮品裤 ",
						"price": "0.01",
						"sku_image": "upload/1/common/images/20230616/20230616090631168692079137773.jpg",
						"promotion_price": "0.01",
						"stock": 92,
						"unit": "",
						"goods_state": 1,
						"verify_state": 1
					}, {
						"sku_id": 21,
						"sku_name": "拉芳奇焕润发精油护发女改善毛躁染烫受损滋润补水持久留香正品 ",
						"price": "1.00",
						"sku_image": "upload/1/common/images/20230616/20230616085920168692036050663.jpg",
						"promotion_price": "1.00",
						"stock": 8,
						"unit": "",
						"goods_state": 1,
						"verify_state": 1
					}]
				}]
			};
		},
		created() {
			this.orderId = this.$route.query.order_id;
			let that = this;
			// this.getOrderDetail();
			setTimeout(function() {
				that.loading = false;
			}, 1000)
		},
		mounted() {
			let self = this;
			console.log(this.$route.query.data)
			let taocanData = {};
			if (this.$route.query.data) {
				taocanData = JSON.parse(this.$route.query.data);
				console.log(taocanData)
				this.taocanData = taocanData;
				this.getKalistFun()

			}
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			duihuan(e) {
				if(!this.bl_id){
					  this.$message.error('请选择福利卡');
					  return
				};
				console.log(e)
				this.$router.pushToTab('/member/fulipay?blId=' + this.bl_id + '&user_kaid=' + this.taocanData.id)
			},
			getKalistFun() {
				getKalist({
						id: this.taocanData.id
					})
					.then(res => {
						if (res.code >= 0) {
							console.log(res, 'apiOrderDetail')
							this.taocan = res.data.taocan

						} else {
							this.$message({
								message: '未获取到订单信息',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/order_list'
									});
								}
							});
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {

							}
						});
					});

			},
			getOrderDetail() {
				apiOrderDetail({
						order_id: this.orderId
					})
					.then(res => {
						if (res.code >= 0) {
							console.log(res, 'apiOrderDetail')
							this.orderDetail = res.data;
							this.loading = false;
						} else {
							this.$message({
								message: '未获取到订单信息',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/order_list'
									});
								}
							});
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					});
			},
			operation(action) {
				switch (action) {
					case 'orderPay': // 支付
						this.orderPay(this.orderDetail);
						break;
					case 'orderClose': //关闭
						this.orderClose(this.orderDetail.order_id, () => {
							this.getOrderDetail();
						});
						break;
					case 'memberTakeDelivery': //收货
						this.orderDelivery(this.orderDetail.order_id, () => {
							this.getOrderDetail();
						});
						break;
					case 'trace': //查看物流
						this.$router.push({
							path: '/member/logistics',
							query: {
								order_id: this.orderDetail.order_id
							}
						});
						break;
					case 'memberOrderEvaluation': //评价
						this.$router.pushToTab({
							path: '/evaluate',
							query: {
								order_id: this.orderDetail.order_id
							}
						});
						break;
				}
			},
			imageError(index) {
				this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage;
			}
		},
		filters: {
			abs(value) {
				return Math.abs(parseFloat(value)).toFixed(2);
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import './detail.scss';

	.img-wrap {
		width: 60px;
		height: 60px;
		float: left;
		margin-right: 10px;
		cursor: pointer;
	}

	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.head {
		padding: 8px 10px;
		background: #f7f7f7;
		font-size: 12px;

		.create-time {
			margin-right: 10px;
		}

		border-bottom: 1px solid #eeeeee;

		a {
			margin: 0 10px 0 20px;
		}

		.order-type {
			margin-left: 30px;
		}
	}

	.order-detail {
		.order-status {
			background-color: #fff;
			margin-bottom: 20px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			.go-pay {
				p {
					display: inline-block;
					vertical-align: middle;

					span {
						font-weight: bold;
						color: $base-color;
						font-size: 18px;
					}
				}
			}

			.operation {
				margin-top: 10px;
			}
		}

		.order-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					// flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}

		.take-delivery-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					// flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 50%;

					&.no-operation {
						width: 60%;
					}
				}

				&:nth-child(2) {
					width: 15%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 15%;
				}

				&:nth-child(5) {
					width: 10%;
				}
			}
		}

		.list {
			border-bottom: 1px solid #eeeeee;

			.item {
				background-color: #fff;
				padding: 10px 0;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 50%;
						line-height: inherit;

						&.no-operation {
							width: 60%;
						}

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 30%;
					}

					&:nth-child(3) {
						width: 20%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 10%;
					}
				}
			}
		}

		// 总计
		.total {
			padding: 20px;
			background-color: #fff;
			text-align: right;

			li {
				span {
					width: 150px;
					display: inline-block;
				}

				&.pay-money {
					font-weight: bold;

					span {
						color: $base-color;
						font-size: 16px;
						vertical-align: middle;
					}
				}
			}
		}
	}

	// 组合套餐
	.bundling-wrap {
		clear: both;
		margin-bottom: 20px;

		.master {
			float: left;
			width: 150px;
			position: relative;

			.sku-img {
				text-align: center;
				width: 100px;
				height: 100px;
				margin-bottom: 10px;
			}

			.sku-name {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.sku-price {
				font-weight: bold;
				font-size: $ns-font-size-base;
			}

			i {
				color: #cccccc;
				font-size: 24px;
				font-weight: bold;
				width: 23px;
				height: 23px;
				overflow: hidden;
				position: absolute;
				right: 0;
				top: 40px;
			}
		}

		.operation {
			float: right;
			position: relative;
			width: 150px;
			padding-left: 50px;
			padding-top: 35px;

			.price-wrap {
				.bl-price {
					font-size: 18px;
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			i {
				overflow: hidden;
				position: absolute;
				left: 0;
				top: 30px;
				text-align: center;
				font-size: 34px;
				font-weight: bold;
				color: #cccccc;
			}
		}

		.suits {
			margin-left: 40px;

			ul {
				overflow: hidden;

				li {
					float: left;
					width: 150px;
					margin-left: 20px;
					cursor: pointer;

					&:first-child {
						margin-left: 0;
					}

					.sku-img {
						text-align: center;
						width: 100px;
						height: 100px;
						margin: 0 auto 10px;
					}

					.sku-name {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}

					.sku-price {
						font-weight: bold;
						font-size: $ns-font-size-base;
					}
				}
			}
		}
	}
</style>